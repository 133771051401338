import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Skills() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="skills-area page-section scroll-to-page" id="skills">
            <div className="custom-container">
                <div className="skills-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-shapes"></i> my skills
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Skillset</span></h1>
                    </div>

                    <div className="row skills text-center">
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-solidworks.png" alt="SolidWorks"/>
                                    <h1 className="percent">95%</h1>
                                </div>
                                <p className="name">SolidWorks</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-robotics.png" alt="Robotics"/>
                                    <h1 className="percent">90%</h1>
                                </div>
                                <p className="name">Robotics</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-physics.png" alt="Physics"/>
                                    <h1 className="percent">90%</h1>
                                </div>
                                <p className="name">Physics</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-python.png" alt="Python"/>
                                    <h1 className="percent">85%</h1>
                                </div>
                                <p className="name">Python</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-matlab.png" alt="MATLAB"/>
                                    <h1 className="percent">80%</h1>
                                </div>
                                <p className="name">MATLAB</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-up'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-mechatronics.png" alt="Mechatronics"/>
                                    <h1 className="percent">80%</h1>
                                </div>
                                <p className="name">Mechatronics</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-up'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-problem-solving.png" alt="Problem Solving"/>
                                    <h1 className="percent">95%</h1>
                                </div>
                                <p className="name">Problem Solving</p>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/icons8-document.png" alt="Documentation"/>
                                    <h1 className="percent">85%</h1>
                                </div>
                                <p className="name">Documentation</p>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
    </section>
  )
}
