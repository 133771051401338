import React from 'react'

export default function Lsb() {
  return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                <span className="name">Zach</span>
                <span className="designation">Mechanical<br/>Engineer, M.S.</span>
            </div>
            <img className="me" src="./assets/images/headshot.jpg" alt="Me"/>
            <h2 className="statement">Searching for <br /><span className="accent">mechanical design role</span></h2>
            <h2 className="email">zacharycwoosley@gmail.com</h2>
            <h2 className="phone">(918) 760 - 8322</h2>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://www.linkedin.com/in/zachary-woosley" target="_blank" rel="noopener noreferrer"><i className="lab la-linkedin"></i></a>
                </li>
            </ul>
            <a href="#contact" className="theme-btn">
                <i className="las la-envelope"></i> Hire Me!
            </a>
        </div>
  )
}   
