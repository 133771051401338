import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>A mechanical design engineer <br/>
                        with a <span>passion for technology</span></h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>I'm a hands-on mechanical design engineer with a multi-disciplinary engineering background. 
                With education covering a wide variety of engineering topics, including mechatronics, software, and biomechanics, I'm ready to take on any problem that comes my way.
                After completing my B.S. in Engineering Physics and my M.S. in Mechanical Engineering with a focus in Automation and Robotics, I landed my first job at Seagate,
                where I designed mechanical and electrical control systems for HDD manufacturing equipment, led a team of technicians maintaining manufacturing clean room robotics,
                and traveled to Singapore to implement manufacturing process improvements. Now, I'm looking for my next challenge.</p>
            </div>
        </div>
    </section>
  )
}
