import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        Lightbox.option({
          resizeDuration: 200,
          fadeDuration: 600,
          imageFadeDuration: 600,
          wrapAround: true
        });
      }, []);
  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> portfolio
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Featured <span>Projects</span></h1>
                </div>

                <div className="row portfolio-items">

                    <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/guzik.png" data-lightbox="example-1">
                                    <img src="../assets/images/guzik.png" alt="Guzik HDD Spinstand Equipment"/>
                                </a>
                                <ul className="portfolio-logo">
                                    <li>
                                        <a href='https://www.seagate.com'><img src="../assets/images/seagate-logo.png" alt='Seagate Logo'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.solidworks.com">SolidWorks</a>
                                    </li>
                                    <li>
                                        <a href="https://www.solidworks.com/product/solidworks-simulation">FEA</a>
                                    </li>
                                    <li>
                                        <a>Robotics</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>Spinstand Equipment Design</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-left'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/uc190.png" data-lightbox="example-1">
                                    <img src="../assets/images/uc190.png" alt="Solid State Cooling Systems UC190 Compact Thermal Chiller"/>
                                </a>
                                <ul className="portfolio-logo">
                                    <li>
                                        <a href='https://www.seagate.com'><img src="../assets/images/seagate-logo.png" alt="Seagate Logo"></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.python.org">Python</a>
                                    </li>
                                    <li>
                                        <a>Test Automation</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>Compact Chiller Test Automation</a></h2>
                        </div>
                    </div>
                    
                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/hdd-manufacturing.jpg" data-lightbox="example-1">
                                    <img src="../assets/images/hdd-manufacturing.jpg" alt="Hard Disk Drive Manufacturing Equipment"/>
                                </a>
                                <ul className="portfolio-logo">
                                    <li>
                                        <a href='https://www.seagate.com'><img src="../assets/images/seagate-logo.png" alt='Seagate Logo'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a>Robotics</a>
                                    </li>
                                    <li>
                                        <a>Problem Solving</a>
                                    </li>
                                    <li>
                                        <a>Documentation</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>Hard Disk Drive Manufacturing and Testing Robotics</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/dbmedx.png" data-lightbox="example-1">
                                    <img src="../assets/images/dbmedx.png" alt="dBMEDx BBS Revolution Ultrasound Device"/>
                                </a>
                                <ul className="portfolio-logo">
                                    <li>
                                        <a href='https://www.dbmedx.com'><img src="../assets/images/dbmedx-logo.png" alt='dBMEDx Logo'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.python.org">Python</a>
                                    </li>
                                    <li>
                                        <a>C</a>
                                    </li>
                                    <li>
                                        <a>Test Automation</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>Ultrasound Test Automation</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-left'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/javafx.jpg" data-lightbox="example-1">
                                    <img src="../assets/images/javafx.jpg" alt="Java Logo"/>
                                </a>
                                <ul className="portfolio-logo">
                                    <li>
                                        <a href='https://www.opttek.com'><img src="../assets/images/opttek-logo.png" alt='OptTek Logo'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a>Java</a>
                                    </li>
                                    <li>
                                        <a>Test Automation</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>OptDef GUI Test Automation</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/floppydrive.jpg" data-lightbox="example-1">
                                    <img src="../assets/images/floppydrive.jpg" alt="Floppy Drive Musical Instrument"/>
                                </a>
                                <ul className="portfolio-logo-large">
                                    <li>
                                        <a href='https://www.mines.edu'><img src="../assets/images/mines-logo.png" alt='Colorado School of Mines Logo'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a>Mechatronics</a>
                                    </li>
                                    <li>
                                        <a>Arduino</a>
                                    </li>
                                    <li>
                                        <a href="https://en.wikipedia.org/wiki/C_(programming_language)">C</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>Floppy Drive Musical Instrument</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/pizero.jpg" data-lightbox="example-1">
                                    <img src="../assets/images/pizero.jpg" alt="Raspberry Pi Zero W"/>
                                </a>
                                <ul className="portfolio-logo-large">
                                    <li>
                                        <a href='https://www.zachwoosley.com'><img src="../assets/images/icons8-z.png" alt='Personal Icon'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a>Mechatronics</a>
                                    </li>
                                    <li>
                                        <a href="https://www.solidworks.com">SolidWorks</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>Raspberry Pi Projects</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-left'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/ankermake.jpg" data-lightbox="example-1">
                                    <img src="../assets/images/ankermake.jpg" alt="Ankermake 3D Printer"/>
                                </a>
                                <ul className="portfolio-logo-large">
                                    <li>
                                        <a href='https://www.zachwoosley.com'><img src="../assets/images/icons8-z.png" alt='Personal Icon'></img></a>
                                    </li>
                                </ul>
                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.solidworks.com">SolidWorks</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a>3D Printed Designs</a></h2>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
  )
}
