import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Education & <span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2021 - 2023</span>
                        <h2>Mechanical Equipment Engineer</h2>
                        <h4>Seagate Technology</h4>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2020 - 2021</span>
                        <h2>M.S. Mechanical Engineering</h2>
                        <h3>Colorado School of Mines</h3>
                        <p>Focus in Automation and Robotics, GPA 4.0</p>

                        <h2>Software Engineering Intern</h2>
                        <h4>OptTek Systems</h4>
                        
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2016 - 2020</span>
                        <h2>B.S. Engineering Physics</h2>
                        <h3>Colorado School of Mines</h3>
                        <p>Summa Cum Laude, GPA 3.9</p>
                        
                        <h2>Engineering Intern</h2>
                        <h4>dBMEDx</h4>

                        <h2>Physics Teaching Assisstant</h2>
                        <h4>Colorado School of Mines</h4>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2016</span>
                        <h2>Graduated High School</h2>
                        <h3>Arapahoe High School</h3>
                        <p>GPA 4.0</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}
