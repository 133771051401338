import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Expertise
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My Areas of <span>Expertise</span></h1>
                </div>

                <div className="services-items">
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-cogs"></i>
                        <h2>Mechanical Design</h2>
                        <p>
                            I design electromechanical systems in SolidWorks, including designing fixtures, performing FEA studies, selecting components, and creating drawings.
                        </p>
                        {/* <span className="projects"></span> */}
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-robot"></i>
                        <h2>Robotics</h2>
                        <p>
                            I have a deep understanding of robotics rooted in my M.S.M.E. focused in Automation and Robotics. 
                            I have knowledge and experience in many areas of robotics including robot dynamics, mechantronics, manufacturing robotics, and biomechanics.
                        </p>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Software</h2>
                        <p>
                            I have experience in Python and MATLAB for process automation and data analysis. 
                            I also have experience in Embedded C and Java, and have tinkered with other languages on my own time including MicroPython, HTML, and Javascript (React). 
                        </p>
                    </div>
                </div>   
            </div>
        </div>
    </section>
  )
}
